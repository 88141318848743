import './Info.css';
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import xlsxImg from './xlsx_template.png';


export default function Info() {
    const navigate = useNavigate();
    const [ showFAQ, setShowFAQ ] = useState(false);
    const startX = useRef(null);
    const startY = useRef(null);
    const infoRef = useRef(null);

    useEffect(() => {
        const handleKeyDown = async (event) => {
            console.log('KEY PRESSED');
            if (event.key === 'ArrowRight') {
                console.log('RIGHT ARROW');
                event.preventDefault();
                navigate('/upload');
            }
        };

        if (infoRef.current) infoRef.current.addEventListener('touchmove', handleTouchMove);
        document.addEventListener('keydown', handleKeyDown);
    
        return () => {
            if (infoRef.current) infoRef.current.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('keydown', handleKeyDown);
        };
    },[]);

    function downloadTemplate() {
        const link = document.createElement('a');
        link.href = process.env.PUBLIC_URL + '/template.csv';
        link.download = 'template.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleTouchStart = (event) => {
        startX.current = event.touches[0].clientX;
        startY.current = event.touches[0].clientY;
    };

    const handleTouchMove = (event) => {
        if (!startX.current || !startY.current) return;

        const currentX = event.touches[0].clientX;
        const currentY = event.touches[0].clientY;
        const deltaX = currentX - startX.current;
        const deltaY = currentY - startY.current;

        if (Math.abs(deltaY) < Math.abs(deltaX)) {
            event.preventDefault();
        }

        if (deltaX < -150) {
            navigate('/upload');
        }
    };

    const handleTouchEnd = () => {
        startX.current = null;
        startY.current = null;
    };
    
  
    return (<div className="info" ref={infoRef} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
        <h2>Requirements for the dictionary files</h2>
        <p><span className="app-name">Y ᴡᴏʀᴅs</span> normally accepts only .xlsx files as well as most simple .csv files with the non-standard semicolon delimiters in the UTF-8 encoding (especially to account for the transcription symbols as well as non-Latin characters of various languages). The .csv files can be easily created from regular Excel files by spreadsheet software or actually even in a Notepad. The non-standard semicolon ( ; ) delimeter for .csv has been chosen to reserve the comma symbols for the separation of transcription variations.</p>
        <p>Both .xlsx and .csv files are basically tables. They are expected to contain the first row to accomodate for the headers of the columns. The content of this row will be ignored by <span className="app-name">Y ᴡᴏʀᴅs</span> except for the number of the actual column headers. The latter should not be less than the number of the actual columns with data in the table below.</p>
        <p>Starting with the second row, each row represents one dictionary word. Each row must at minimum contain the word in the laguage to be studied and this word's translation. After that there may be optionally a transcription of the word to be studied (without any brackets), and then optionally a transcription for the translation.</p>
        <p>A five-word dictionary in .xlsx should look like in the picture below.</p>
        <img id="xlsx-img" src={xlsxImg}/>
        <p>Below there is a sample of a five-word dictionary for .csv file.</p>
        <code>Foreign word<span className="comma">;</span>Native word<span className="comma">;</span>Foreign word transcription<span className="comma">;</span>Native word transcription<br/>
        loup (m.)<span className="comma">;</span>wolf<span className="comma">;</span>lu<span className="comma">;</span>wʊlf<br/>
        forêt (f.)<span className="comma">;</span>forest<span className="comma">;</span>fɔʀɛ<span className="comma">;</span>ˈfɔːrɪst, ˈfɑːrɪst<br/>
        pin (m.)<span className="comma">;</span>pine<span className="comma">;</span>pɛ̃<span className="comma">;</span>paɪn<br/>
        fourchette (f.)<span className="comma">;</span>fork<span className="comma">;</span>fuʀʃɛt<span className="comma">;</span>fɔːrk<br/>
        respect (m.)<span className="comma">;</span>respect<span className="comma">;</span>ʀɛspɛ<span className="comma">;</span>rɪˈspekt</code>
        <h3>FAQ</h3>
        { showFAQ ? 
            <div className="open-faq">
                <div className="faq-content">
                    <IoIosArrowUp className="close-faq-button" onClick={() => setShowFAQ(prevShowFAQ => !prevShowFAQ)}/>
                    <h4>Can my dictionary file have an extention different from .csv and .xlsx?</h4>
                    <p>No. The file selection mechanism is not expected to allow for choosing a file with an extention different from .xlsx or .csv for an upload.</p>
                    <h4>Can I remove the header from my dictionary file if the headers in it are ignored anyway?</h4>
                    <p>No. If you completely remove the header and the first row contains the first dictionary word, the file will be successfully uploaded (if it has more than one dictionary word) but this first word is going to be ignored. Moreover, the number of columns in that first row is going to determine the number of columns for all other dictionary words as usually happens with the header row in <span className="app-name">Y ᴡᴏʀᴅs</span>.</p>
                    <h4>What if some rows below the header are completely empty?</h4>
                    <p>Such completely empty rows (those that do not contain any characters including the space character and semicolons) will be ignored in a .csv dictionary. There will be no empty cards due to that. However if the row in a .csv file contains empty columns but has semicolon demiliters, in that case there will be empty cards in your dictionary set.</p>
                    <h4>Can I assign the same name to different word sets?</h4>
                    <p>No. If you choose the same name as the name already assigned to another word set when uploading a dictionary file, <span className="app-name">Y ᴡᴏʀᴅs</span> will make multiple attempts to automatically select a different name (the selected name plus a number). If successful, you will find the uploaded word set with this corrected name.</p>
                    <h4>What if I upload the same dictionary file more than once?</h4>
                    <p><span className="app-name">Y ᴡᴏʀᴅs</span> will not prevent that. It will only ensure that each upload results in a word set with a different name. But the sets are going to be identical.</p>
                    <h4>Can I use quotes as escape characters for semicolons in .csv?</h4>
                    <p>Althout it is allowed by the .csv format, <span className="app-name">Y ᴡᴏʀᴅs</span> does not support it. The embracing quotes will be treated as regular printable characters and semicolons inside of them will be interpreted as real column separators.</p>
                </div>
            </div> : 
            <div className="closed-faq">
                <IoIosArrowDown className="open-faq-button shaking-arrow" onClick={() => setShowFAQ(prevShowFAQ => !prevShowFAQ)}/>
            </div> }
        <div className="buttons">
            <button className="menuButton" onClick={downloadTemplate}>
                <em>T</em>emplate&nbsp;file
            </button>
            <Link to="/upload">
                <button className="menuButton">
                    <em>B</em>ack&nbsp;to&nbsp;upload
                </button>
            </Link>
        </div>
        <footer>
            <p>&copy; 2023 Y SOFT LLC</p>
            <p><a href="https://ysoft.pro">ysoft.pro</a></p>
            <p className="widestText">THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.</p>
            <br/><br/>
            <div className="bottom-margin"></div>
        </footer> 
    </div>);
}