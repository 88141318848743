import './Answer.css';
import { useEffect, useRef } from 'react';


export default function Answer({ status, correctStable, correct, word, directMode,  correctAnswer, incorrectAnswer }) {
  
    return (<div onClick={correctStable ? correctAnswer : () => incorrectAnswer(word.foreign)} className={`answer ${status ? 'question' : 'answer-option'} ${correct ? 'correct' : correct == false ? 'incorrect' : ''}`}>
            { (directMode && status) || (!directMode && !status) ?
                <div className="word-text">
                    {word ? word.foreign : ''}
                    {word && word.foreignTranscription && status ? <><br/><span className="transcription">[{word.foreignTranscription}]</span></> : <></>}
                    {word && word.foreignTranscription && !status && correct ? <>&nbsp;&nbsp;&nbsp;<span className="transcription">[{word.foreignTranscription}]</span></> : <></>}
                </div> 
            : 
                <div className="word-text">
                    {word ? word.native : ''}
                    {word && word.nativeTranscription && status ? <><br/><span className="transcription">[{word.nativeTranscription}]</span></> : <></>}
                    {word && word.nativeTranscription && !status && correct ? <>&nbsp;&nbsp;&nbsp;<span className="transcription">[{word.nativeTranscription}]</span></> : <></>}
                </div>
            }
    </div>);
}