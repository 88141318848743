import './Menu.css';
import { useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";


export default function Menu(args) {
    const navigate = useNavigate();
    const startX = useRef(null);
    const startY = useRef(null);
    const menuRef = useRef(null);

    useEffect(() => {
        const handleKeyDown = async (event) => {
            if (event.key === 'ArrowLeft') {
                event.preventDefault();
                navigate('/upload');
            } else if (event.key === 'ArrowRight') {
                event.preventDefault();
                navigate('/dictionaries');
            }
        };

        if (menuRef.current) menuRef.current.addEventListener('touchmove', handleTouchMove);
        document.addEventListener('keydown', handleKeyDown);
      
        return () => {
            if (menuRef.current) menuRef.current.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('keydown', handleKeyDown);
        };
    },[]);

    const handleTouchStart = (event) => {
        startX.current = event.touches[0].clientX;
        startY.current = event.touches[0].clientY;
    };

    const handleTouchMove = (event) => {
        if (!startX.current || !startY.current) return;

        const currentX = event.touches[0].clientX;
        const currentY = event.touches[0].clientY;
        const deltaX = currentX - startX.current;
        const deltaY = currentY - startY.current;

        if (Math.abs(deltaY) < Math.abs(deltaX)) {
            event.preventDefault();
        }

        if (deltaX > 150) {
            navigate('/upload');
        } else if (deltaX < -150) {
            navigate('/dictionaries');
        }
    };

    const handleTouchEnd = () => {
        startX.current = null;
        startY.current = null;
    };
    
  
    return (<div className="menu" ref={menuRef} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
        <div className="text-container">
            <div className="welcome"><h1>Welcome to <span className="app-name-title">|&nbsp;Y&nbsp;ᴡᴏʀᴅs</span></h1></div>
            <div className="description">
                <p>
                    A <span className='flip-word'>flashcard</span> application for studying <strong>foreign words</strong> and their pronunciation (transcription) or even domain-agnostic terminology with a democratic dictionary file format.<br/><br/>
                    This web application can be <span className='jump-word'>installed</span> on your smartphone by adding to the home screen from the browser.
                </p>
            </div>
        </div>
        <div className="buttons">
            <Link to="/upload">
                <button className="menuButton">
                    <em>U</em>pload new dictionary
                </button>
            </Link>
            <Link to="/dictionaries">
                <button className="menuButton">
                    <em>D</em>ictionaries
                </button>
            </Link>
        </div>
    </div>);
}