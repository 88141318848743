import './Upload.css';
import { useState, useEffect, useRef } from 'react';
import { useIndexedDB } from 'react-indexed-db-hook';
import { Link, useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';

const indeces = ["foreign", "native", "foreignTranscription", "nativeTranscription"];

export default function Upload(args) {
    const [ uploadSuccess, setUploadSuccess ] = useState(false);
    const navigate = useNavigate();
    const [ file, setFile ] = useState(null);
    const [ dictName, setDictName ] = useState('');
    const [ csvContent, setCsvContent ] = useState();
    const { add: addWords } = useIndexedDB('words');
    const { add: addDictionaries, getAll: getAllDictionaries } = useIndexedDB('dictionaries');
    const startX = useRef(null);
    const startY = useRef(null);
    const uploadRef = useRef(null);

    useEffect(() => {
        const handleKeyDown = async (event) => {
            if (event.key === 'ArrowRight') {
                if (document.activeElement.tagName !== 'INPUT') {
                    event.preventDefault();
                    navigate('/');
                }
            } else if (event.key === 'ArrowLeft') {
                if (document.activeElement.tagName !== 'INPUT') {
                    event.preventDefault();
                    navigate('/info');
                }
            }
        };

        if (uploadRef.current) uploadRef.current.addEventListener('touchmove', handleTouchMove);
        document.addEventListener('keydown', handleKeyDown);
      
        return () => {
            if (uploadRef.current) uploadRef.current.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('keydown', handleKeyDown);
        };
    },[]);

    useEffect(() => {
        const addWithGoodName = (goodName) => {
            addDictionaries({dictionaryName: goodName}).then(
                (event) => {
                    console.log("ID Generated");
                },
                (error) => {
                    console.log(error);
                },
            );
            for (const obj of csvContent) {
                addWords({...obj, progress: 12, dictionary: goodName}).then(
                    (event) => {
                        console.log("ID Generated");
                    },
                    (error) => {
                        console.log(error);
                    },
                );
            }
        };

        if (csvContent !== undefined) {
            getAllDictionaries().then(theDictionaries => {
                if (!theDictionaries.some(obj => obj.dictionaryName === dictName)) addWithGoodName(dictName);
                else {
                    for (let i = 2; i < 201; i++) {
                        if (i === 200) {
                            setDictName('Please choose another name');
                            return;
                        } else {
                            const newName = `${dictName} ${i}`;
                            if (!theDictionaries.some(obj => obj.dictionaryName === newName)) {
                                addWithGoodName(newName);
                                return;
                            }
                        }
                    };
                }
            });
        }
    }, [csvContent]);
    

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        setDictName(selectedFile.name.split('.csv')[0].split('.xlsx')[0]);
    };

    const handleFileUpload = async () => {
        if (file && file.name.endsWith('.xlsx')) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const xlsxData = e.target.result;
                const workbook = XLSX.read(xlsxData, { type: 'array' });
                const sheet = workbook.Sheets[workbook.SheetNames[0]];
                const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                if (rows && rows.length > 0) {
                    const header = rows[0].slice(0, 4);
                    const data = rows.slice(1);
                    if (data && data.length > 0) {
                        const newDataArray = data.map(row => {
                            const obj = {};
                            header.forEach((col, index) => {
                                obj[indeces[index]] = row[index];
                            });
                            return obj;
                        });
                        setCsvContent(newDataArray);
                        console.log(newDataArray);
                        setUploadSuccess(true);
                    }
                }
                setUploadSuccess(true);
            };

            reader.readAsArrayBuffer(file);
        } else if (file && file.name.endsWith('.csv')) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const csvData = e.target.result;
                const rows = csvData.split(/\r?\n/).filter(row => row !== '').map(row => row.split(';'));
                if (rows && rows.length > 0) {
                    const header = rows[0].slice(0, 4);
                    const data = rows.slice(1);
                    if (data && data.length > 0) {
                        const newDataArray = data.map(row => {
                            const obj = {};
                            header.forEach((col, index) => {
                                obj[indeces[index]] = row[index];
                            });
                            return obj;
                        });
                        setCsvContent(newDataArray);
                        console.log(newDataArray);
                        setUploadSuccess(true);
                    }
                }
            };

            reader.readAsText(file, 'UTF-8');
        }
    };

    const handleTouchStart = (event) => {
        startX.current = event.touches[0].clientX;
        startY.current = event.touches[0].clientY;
    };

    const handleTouchMove = (event) => {
        if (!startX.current || !startY.current) return;

        const currentX = event.touches[0].clientX;
        const currentY = event.touches[0].clientY;
        const deltaX = currentX - startX.current;
        const deltaY = currentY - startY.current;

        if (Math.abs(deltaY) < Math.abs(deltaX)) {
            event.preventDefault();
        }

        if (deltaX > 150) {
            navigate('/info');
        } else if (deltaX < -150) {
            navigate('/');
        }
    };

    const handleTouchEnd = () => {
        startX.current = null;
        startY.current = null;
    };

    return (
        <div className="upload" ref={uploadRef} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
                { uploadSuccess && <div className="upload-success"><p className="upload-p">You uploaded the file</p><button className="upload-ok" onClick={()=> {
                    setUploadSuccess(false);
                    setDictName('');
                }}>Ok</button></div> }
            <input type="file" className="csvFileInput" accept=".csv, .xlsx" onChange={handleFileChange} />
            <input type="text" className="dictNameInput" value={dictName} onChange={(e) => setDictName(e.target.value)} />
            <button className="uploadButton" onClick={handleFileUpload}><em>U</em>pload&nbsp;new&nbsp;dictionary</button>
            <div className="buttons">
                <Link className="linkToMenu" to="/info">
                    <button className="menuButton">
                        <em>F</em>ile format info
                    </button>
                </Link>
                <Link className="linkToMenu" to="/">
                    <button className="menuButton">
                        <em>B</em>ack to menu
                    </button>
                </Link>
            </div>
        </div>
    );
};