import './App.css';
import { Route, Routes } from "react-router-dom";
import Menu from "./Menu";
import Info from "./Info";
import Upload from "./Upload";
import Learning from "./Learning";
import Quiz from "./Quiz";
import Dictionaries from "./Dictionaries";
import { useState, useEffect } from 'react';
import { initDB } from "react-indexed-db-hook";
import { DictionaryConfig } from "./DBConfig";
import logoImg from './logo.png';
import { useIndexedDB } from 'react-indexed-db-hook';
import { PiHandSwipeLeftThin, PiHandSwipeRightThin, PiHandTapThin } from "react-icons/pi";

const indeces = ["foreign", "native", "foreignTranscription", "nativeTranscription"];
initDB(DictionaryConfig);

export default function App() {
  const [ db, setDB ] = useState();
  const [ selectedDict, setSelectedDict ] = useState(localStorage.getItem('selectedDict'));
  const [ greetingInvisible, setGreetingInvisible ] = useState(false);
  const [ greetingFlipped, setGreetingFlipped ] = useState(false);
  const [ directMode, setDirectMode ] = useState(true);
  const [ quizMode, setQuizMode ] = useState(false);
  const { add: addWords } = useIndexedDB('words');
  const { add: addDictionaries, getAll: getAllDictionaries } = useIndexedDB('dictionaries');
  const [ csvContent, setCsvContent ] = useState();
  const [ dictName, setDictName ] = useState('');
  const [ uploadSuccess, setUploadSuccess ] = useState(false);

  const toggleFullScreen = () => {
    const doc = window.document;
    const docEl = doc.documentElement;

    const isFullScreen = doc.fullscreenElement || doc.mozFullScreenElement || doc.webkitFullscreenElement || doc.msFullscreenElement;

    if (!isFullScreen) {
      if (docEl.requestFullscreen) {
        docEl.requestFullscreen();
      } else if (docEl.mozRequestFullScreen) {
        docEl.mozRequestFullScreen();
      } else if (docEl.webkitRequestFullscreen) {
        docEl.webkitRequestFullscreen();
      } else if (docEl.msRequestFullscreen) {
        docEl.msRequestFullscreen();
      }
    } else {
      if (doc.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        doc.mozCancelFullScreen();
      } else if (doc.webkitExitFullscreen) {
        doc.webkitExitFullscreen();
      } else if (doc.msExitFullscreen) {
        doc.msExitFullscreen();
      }
    }
  };

  useEffect(() => {
    const addWithGoodName = (goodName) => {
        
    };

    addWithGoodName(dictName);
  }, [csvContent]);

  async function uploadDemo(demoFile, demoName) {
    try {
      const response = await fetch('/' + demoFile);
      console.log(`status: `, response.status, response.body);
      if (!response.ok) {
        throw new Error(`Failed to fetch CSV file: ${response.status}`);
      } else {
        console.log('ATTENTION', response.ok, response.status);
        const csvData = await response.text();
        if (csvData.startsWith('<!DOCTYPE html>')) throw new Error(`There is no file ${demoFile} at the server`);
        else {
          const rows = csvData.split(/\r?\n/).filter(row => row !== '').map(row => row.split(';'));
          if (rows && rows.length > 0) {
              console.log(`ROWS`, rows);
              const header = rows[0].slice(0, 4);
              const data = rows.slice(1);
              if (data && data.length > 0) {
                  const newDataArray = data.map(row => {
                      const obj = {};
                      header.forEach((col, index) => {
                          obj[indeces[index]] = row[index];
                      });
                      return obj;
                  });
                  addDictionaries({dictionaryName: demoName}).then(
                      (event) => {
                          console.log("ID Generated for ", demoName);
                      },
                      (error) => {
                          console.log(error);
                      },
                  );
                  for (const obj of newDataArray) {
                      addWords({...obj, progress: 12, dictionary: demoName}).then(
                          (event) => {
                              console.log("ID Generated for ", demoName);
                          },
                          (error) => {
                              console.log(error);
                          },
                      );
                  }
              }
          }
        }
      }
    } catch (error) {
      console.error(`Error fetching or parsing a demo CSV file ${demoFile}:`, error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const demoFile_1 = 'demo_FR_EN_50.csv';
      const demoName_1 = 'demo FR-EN 50 words';

      const demoFile_2 = 'demo_ES_EN_414_A1.csv';
      const demoName_2 = 'demo ES-EN 414 words A1';

      getAllDictionaries().then(async (theDictionaries) => {
        if (!theDictionaries.some(obj => obj.dictionaryName === demoName_1)) {
          uploadDemo(demoFile_1, demoName_1);
        }
        if (!theDictionaries.some(obj => obj.dictionaryName === demoName_2)) {
          uploadDemo(demoFile_2, demoName_2);
        }
      });
    };

    fetchData();

    const handleKeyDown = (event) => {
      if (event.key === 'f') {
        toggleFullScreen();
      }
    };

    const timeoutId1 = setTimeout(() => {
      setGreetingFlipped(true);
    }, 1500);

    const timeoutId2 = setTimeout(() => {
      setGreetingFlipped(false);
    }, 4000);

    const timeoutId3 = setTimeout(() => {
      setGreetingInvisible(true);
    }, 9000);

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      clearTimeout(timeoutId1);
      clearTimeout(timeoutId2);
      clearTimeout(timeoutId3);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="App">
      <PiHandSwipeLeftThin id='swipe-left' className='swipe-finger' />
      <PiHandSwipeRightThin id='swipe-right' className='swipe-finger' />
      <PiHandTapThin id='swipe-up' className='swipe-finger'/>
      <PiHandTapThin id='swipe-down' className='swipe-finger'/>
      <div className={`greeting ${greetingInvisible ? 'invisible' : ''}`}>
        <div className={`greeting-inner ${greetingFlipped ? 'flipped' : ''}`}>
          <div className={`greeting-front`}>
            <div className="main_logo_container">
              <img id="main_logo" src={logoImg}/>
            </div>
            <div className="front-text">
              <p>&copy; 2023 Y SOFT LLC</p>
              <p>All rights reserved</p>
              <p><a href="https://ysoft.pro">ysoft.pro</a></p>
            </div>
          </div>
          <div className={`greeting-back`}>
            <div className="back-text">
              <p>&copy; 2023 Y SOFT LLC</p>
              <p>All rights reserved</p>
              <p><a href="https://ysoft.pro">ysoft.pro</a></p>
              <p className="widestText">THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.</p>
            </div>
          </div>
        </div>
      </div>
      <Routes>
          <Route path="index.html" element={<Menu db={db} setDB={setDB}/>}/>
          <Route path="/" element={<Menu db={db} setDB={setDB}/>}/>
          <Route path="/upload" element={<Upload db={db} setDB={setDB}/>}/>
          <Route path="/info" element={<Info/>}/>
          <Route path="/dictionaries" element={<Dictionaries db={db} setDB={setDB} selectedDict={selectedDict} setSelectedDict={setSelectedDict} directMode={directMode} setDirectMode={setDirectMode} quizMode={quizMode} setQuizMode={setQuizMode}/>}/>
          <Route path="/learning" element={<Learning db={db} setDB={setDB} selectedDict={selectedDict} directMode={directMode}/>}/>
          <Route path="/quiz" element={<Quiz db={db} setDB={setDB} selectedDict={selectedDict} directMode={directMode}/>}/>
      </Routes>
    </div>
  );
}
