import './Quiz.css';
import { useIndexedDB } from 'react-indexed-db-hook';
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Answer from './Answer';
import { nanoid } from 'nanoid';


export default function Quiz(args) {
    const navigate = useNavigate();
    const [isGlowing, setGlowing] = useState(false);
    const { getAll, update } = useIndexedDB("words");
    const [ words, setWords ] = useState([]);
    const [ change, setChange ] = useState(false);
    const [ word, setWord ] = useState(null);
    const wordRef = useRef(word);
    const [ allLearnt, setAllLearnt ] = useState(false);
    const [ learnAgain, setLearnAgain ] = useState(false);
    const [ propProgress, setPropProgress ] = useState(false);
    const startX = useRef(null);
    const startY = useRef(null);
    const [ launch, setLaunch ] = useState(true);
    const quizRef = useRef(null);
    const [ answerOptions, setAnswerOptions ] = useState([]);
    const wordNumber = useRef(null);
    const [ place, setPlace ] = useState(null);
    const [ wrongAnswer, setWrongAnswer ] = useState(null);
    const [ answered, _setAnswered ] = useState(false);
    const answeredRef = useRef(answered);

    function setAnswered(value) {
        answeredRef.current = value;
        _setAnswered(value);
    }

    useEffect(() => {
        if (learnAgain) {
            setWords(prevWords => (prevWords.map(_word => {
                update({..._word, progress: 12});
                return {..._word, progress: 12};
            })));
            setAllLearnt(false);
            setLearnAgain(false);
        }
    }, [learnAgain]);

    useEffect(() => {
        if (change) {
            const wordId = word ? word.foreign : null;
            const filteredWords = words.filter(obj => obj.foreign !== wordId);
            const randomCumProgree = filteredWords.reduce((sum, obj) => sum + obj.progress, 0) * Math.random();
            if (randomCumProgree === 0 && word) {
                if (word.progress < 1) { 
                    setChange(false);
                    setAllLearnt(true);
                    setWrongAnswer(null);
                    setAnswered(false);
                    return;
                }
                else {
                    setChange(false);
                    setWrongAnswer(null);
                    setAnswered(false);
                    return;
                }
            }
            
            let cumProgress = 0;
            for (const word3 of filteredWords) {
                if (word3.progress === 0) continue;
                cumProgress += word3.progress;
                if (randomCumProgree <= cumProgress) {
                    setWord(word3);
                    wordRef.current = word3;
                    if (wordNumber.current === 0) break;
                    const optionsSize = Math.min(5, wordNumber.current);
                    const filteredWordsForOptions = words.filter(obj => obj.foreign !== word3.foreign);
                    const copyArray = filteredWordsForOptions.slice();
                    // Fisher-Yates algorithm
                    for (let i = wordNumber.current - 1; i > 0; i--) {
                        const randomInt = Math.floor(Math.random() * (i + 1));
                        [copyArray[i], copyArray[randomInt]] = [copyArray[randomInt], copyArray[i]];
                    }
                    const sample = copyArray.slice(0, optionsSize);
                    const randomPlace = Math.floor(Math.random() * (optionsSize + 1));
                    setPlace(randomPlace);
                    sample.splice(randomPlace, 0, word3);
                    setAnswerOptions(sample);
                    setWrongAnswer(null);
                    setAnswered(false);
                    if (answered && wrongAnswer === null) setPropProgress(true);
                    break;
                }
            }
            if (launch) {
                setChange(false);
                setLaunch(false);
            }
            else {
                setTimeout(() => {
                    return setChange(false);
                }, 500);
            }
        }
    }, [change]);

    const handleKeyDown = async (event) => {
        if (event.key === 'ArrowRight') {
            event.preventDefault();
            setChange(true);
        } else if (event.key === 'ArrowLeft') {
            event.preventDefault();
            navigate('/dictionaries');
        }
    };

    useEffect(() => {
        if (propProgress) {
            const newData = words.map(_word =>
                _word.id === word.id ? { ..._word, progress: Math.max(_word.progress - 2, 0) } : _word
            );
            setWords(newData);
            setPropProgress(false);
        }
    }, [propProgress]);

    useEffect(() => {
        setChange(true);
    }, [words]);

    const handleTouchStart = (event) => {
        startX.current = event.touches[0].clientX;
        startY.current = event.touches[0].clientY;
    };

    const handleTouchEnd = () => {
        startX.current = null;
        startY.current = null;
    };

    useEffect(() => {
        if (args.selectedDict === null) navigate('/dictionaries');

        function handleTouchMove(event) {
            if (!startX.current || !startY.current) {
                return;
            } else { 
                const currentX = event.touches[0].clientX;
                const currentY = event.touches[0].clientY;
        
                const deltaX = currentX - startX.current;
                const deltaY = currentY - startY.current;
    
                event.preventDefault();
        
                if (deltaX > 150) {
                    startX.current = null;
                    startY.current = null;
                    navigate('/dictionaries');
                } else if (deltaX < -120) {
                    setChange(true);
                    startX.current = null;
                    startY.current = null;
                }
            }
        }

        getAll().then((theWords) => {
            const theWordsInSelectedDict = theWords.filter(obj => obj.dictionary === args.selectedDict);
            wordNumber.current = theWordsInSelectedDict.length - 1;
            setWords(theWordsInSelectedDict);
            setAllLearnt(theWordsInSelectedDict.reduce((sum, obj) => sum + obj.progress, 0) === 0);
        });
            
        if (quizRef.current) {
            quizRef.current.addEventListener('touchmove', handleTouchMove, { capture: true, passive: false });
            quizRef.current.addEventListener('touchstart', handleTouchStart, { capture: true, passive: false });
            quizRef.current.addEventListener('touchend', handleTouchEnd, { capture: true, passive: false });
        }
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            if (quizRef.current) {
                quizRef.current.removeEventListener('touchmove', handleTouchMove);
                quizRef.current.removeEventListener('touchstart', handleTouchStart);
                quizRef.current.removeEventListener('touchend', handleTouchEnd);
            }
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    function updateWordProgress() {
        setWord(prevWord => ({...prevWord, progress: Math.max(prevWord.progress - 2, 0)}));
        update({...word, progress: Math.max(word.progress - 2, 0)});
    }

    function correctAnswer() {
        if (!answered) {
            setWrongAnswer(null);
            setAnswered(true);
            updateWordProgress();
            setTimeout(() => {    
                if(answeredRef.current && wordRef.current.foreign == word.foreign) {
                    setChange(true);
                }
            }, 2000);
        }
    }

    function incorrectAnswer(id) {
        if (!answered) {
            setWrongAnswer(id);
            setAnswered(true);
        }
    }
  
    if (!allLearnt) return (<div ref={quizRef} className="quiz">
                            <div className="dictTitle">{args.selectedDict}</div>
                                { word !== null && <Answer key={word.foreign} className="question" status={true} word={word} directMode={args.directMode}/> }
                                {
                                    answerOptions.map((_word, i) => {
                                        if (i === place) return <Answer key={nanoid()} correctStable={true} correct={answered ? true : null} status={false} word={_word} directMode={args.directMode} correctAnswer={correctAnswer} incorrectAnswer={incorrectAnswer} onClick={correctAnswer}/>
                                        else if (_word.foreign === wrongAnswer) return <Answer key={_word.foreign} correctStable={false} correct={false} status={false} word={_word} directMode={args.directMode} correctAnswer={correctAnswer} incorrectAnswer={incorrectAnswer} onClick={() => incorrectAnswer(_word.foreign)}/>
                                        else return <Answer key={_word.foreign} correctStable={false} correct={null} status={false} word={_word} directMode={args.directMode} correctAnswer={correctAnswer} incorrectAnswer={incorrectAnswer} onClick={() => incorrectAnswer(_word.foreign)}/>
                                    })
                                }
                            </div>);

   else return (
        <div className="quiz">
            <div className="all-learnt">
                <p>You learnt all the words in this dictionary</p>
                <div className="all-learnt-container">
                    <Link to="/">
                        <button className="menuButton">
                            <em>B</em>ack to menu
                        </button>
                    </Link>
                    <button className="menuButton" onClick={()=> {
                            setLearnAgain(true);
                        }}>
                        <em>L</em>earn this dictionary again
                    </button>
                </div>
            </div>
        </div>
    );
}