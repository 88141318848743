import './Dictionaries.css';
import { Link, useNavigate } from "react-router-dom";
import { useIndexedDB } from 'react-indexed-db-hook';
import { useState, useEffect, useRef } from 'react';
import { AiOutlineDelete } from "react-icons/ai";
import { IoToggle, IoToggleOutline } from "react-icons/io5";


export default function Dictionaries(args) {
    const navigate = useNavigate();
    const { getAll: getAllDictionaries, deleteRecord: deleteDictionary } = useIndexedDB("dictionaries");
    const { getAll: getAllWords, deleteRecord: deleteWord } = useIndexedDB("words");
    const [ dicts, setDict ] = useState([]);
    const [ alreadySelected, setAlreadySelected ] = useState(args.selectedDict);
    const startX = useRef(null);
    const startY = useRef(null);
    const dictionariesRef = useRef(null);

    useEffect(() => {
        getAllDictionaries().then((theDicts) => {
            setDict(theDicts);
        });
    },[]);

    useEffect(() => {
        const handleKeyDown = async (event) => {
            if (event.key === 'ArrowRight') {
                event.preventDefault();
                if (alreadySelected) {
                    if (args.quizMode) navigate('/quiz');
                    else navigate('/learning');
                }
            } else if (event.key === 'ArrowLeft') {
                event.preventDefault();
                navigate('/');
            }
        };

        if (dictionariesRef.current) dictionariesRef.current.addEventListener('touchmove', handleTouchMove);
        document.addEventListener('keydown', handleKeyDown);
        
        return () => {
            if (dictionariesRef.current) dictionariesRef.current.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('keydown', handleKeyDown);
        };
    },[args.quizMode]);

    function deleteOneDictionary(dictName, dictId) {
        getAllWords().then((theWords) => {
            theWords.forEach(_word => {
                if (_word.dictionary === dictName) deleteWord(_word.id);
            });
            deleteDictionary(dictId);
            setDict(prevDicts => {return prevDicts.filter(item => item.id !== dictId)});
            console.log(`The dictionary ${dictName} with ID ${dictId} was deleted`);
            args.setSelectedDict(null);
            localStorage.removeItem('selectedDict');
            setAlreadySelected(null);
        });
    }

    function deleteAllDictionaries() {
        getAllWords().then(theWords => theWords.forEach(_word => deleteWord(_word.id)));
        getAllDictionaries().then(theDictionaries => theDictionaries.forEach(_dict => deleteDictionary(_dict.id)));
        setDict([]);
        console.log(`All the dictionaries were deleted`);
        args.setSelectedDict(null);
        localStorage.removeItem('selectedDict');
        setAlreadySelected(null);
    }

    const handleTouchStart = (event) => {
        startX.current = event.touches[0].clientX;
        startY.current = event.touches[0].clientY;
    };

    const handleTouchMove = (event) => {
        if (!startX.current || !startY.current) return;

        const currentX = event.touches[0].clientX;
        const currentY = event.touches[0].clientY;
        const deltaX = currentX - startX.current;
        const deltaY = currentY - startY.current;

        if (Math.abs(deltaY) < Math.abs(deltaX)) {
            event.preventDefault();
        }

        if (deltaX > 150) {
            navigate('/');
        } else if (alreadySelected && deltaX < -150) {
            if (alreadySelected) {
                if (args.quizMode) navigate('/quiz');
                else navigate('/learning');
            }
        }
    };

    const handleTouchEnd = () => {
        startX.current = null;
        startY.current = null;
    };
  
    return (<div className="dictionaries" ref={dictionariesRef} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
        { dicts.length > 0 && <>
            <div className='mode-div'><span className='mode-label'>Reverse study mode&nbsp;&nbsp;</span>{args.directMode ? <IoToggleOutline className='mode-toggle-off' onClick={() => args.setDirectMode(false)}/> : <IoToggle className='mode-toggle' onClick={() => args.setDirectMode(true)}/>}</div>
            <div className='mode-div'><span className='mode-label'>Quiz mode&nbsp;&nbsp;</span>{!args.quizMode ? <IoToggleOutline className='mode-toggle-off' onClick={() => args.setQuizMode(true)}/> : <IoToggle className='mode-toggle' onClick={() => args.setQuizMode(false)}/>}</div>
        </>}
        
            { dicts.length === 0 ? <div className="no-dicts">
            <p>You have no dictionaries yet.<br/>Do you want to upload your dictionary?</p>
            <div>
                <Link className="linkToMenu" to="/info">
                    <button className="menuButton">
                        <em>R</em>ead about file format
                    </button>
                </Link>
                <Link className="linkToMenu" to="/upload">
                    <button className="menuButton">
                        <em>U</em>pload your dictionary
                    </button>
                </Link>
            </div>
        </div> :
        <button className="dictSelectAll" onClick={deleteAllDictionaries}>
            Delete all dictionaries
        </button>}
        { 
            dicts.map(dict=>{if (dict!==undefined) return (<div key={dict.id}>
                    <Link className="linkSelect" to={args.quizMode ? '/quiz' : '/learning'}>
                        <button className="dictSelect" onClick={() => {
                                args.setSelectedDict(dict.dictionaryName);
                                localStorage.setItem('selectedDict', dict.dictionaryName);
                                setAlreadySelected(dict.dictionaryName);
                            }}>
                            {dict.dictionaryName}
                        </button>
                    </Link>
                    <button className="removeOneButton" onClick={() => deleteOneDictionary(dict.dictionaryName, dict.id)}>
                        <AiOutlineDelete />
                    </button>
                </div>
                )})
            }
            <div className="bottom-margin"></div>
            <div className="buttons">
                <Link className="linkToMenu" to="/">
                    <button className="menuButton">
                        <em>B</em>ack to menu
                    </button>
                </Link>
                <Link className="linkToMenu" to={`/${args.quizMode ?  'quiz' : 'learning'}`}>
                    <button className={`menuButton ${!alreadySelected || (dicts.length === 0) ? 'nothing-to-study-button' : ''}`} disabled={!alreadySelected || (dicts.length === 0)}>
                        <em>S</em>tudy
                    </button>
                </Link>
            </div>
    </div>);
}