export const DictionaryConfig = {
    name: "ywordsDB",
    version: 1,
    objectStoresMeta: [
      {
        store: "words",
        storeConfig: { keyPath: "id", autoIncrement: true },
        storeSchema: [
            { name: "dictionary", keypath: "dictionary", options: { unique: false } },
            { name: "foreign", keypath: "foreign", options: { unique: false } },
            { name: "native", keypath: "native", options: { unique: false } },
            { name: "foreignTranscription", keypath: "foreignTranscription", options: { unique: false } },
            { name: "nativeTranscription", keypath: "nativeTranscription", options: { unique: false } },
            { name: "progress", keypath: "progress", options: { unique: false } },
        ],
      },
      {
        store: "dictionaries",
        storeConfig: { keyPath: "id", autoIncrement: true },
        storeSchema: [
            { name: "dictionaryName", keypath: "dictionaryName", options: { unique: true } },
        ],
      },
    ],
  };