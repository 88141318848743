import './Learning.css';
import { useIndexedDB } from 'react-indexed-db-hook';
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";


export default function Learning(args) {
    const navigate = useNavigate();
    const [isGlowing, setGlowing] = useState(false);
    const { getAll, update } = useIndexedDB("words");
    const [ words, setWords ] = useState([]);
    const [ change, setChange ] = useState(false);
    const [ word, setWord ] = useState(null);
    const [ isFlipped, setFlipped ] = useState(false);
    const isFlippedRef = useRef(isFlipped);
    const [ allLearnt, setAllLearnt ] = useState(false);
    const [ learnAgain, setLearnAgain ] = useState(false);
    const [ propProgress, setPropProgress ] = useState(false);
    const [ saveProgress, setSaveProgress ] = useState(false);
    const startX = useRef(null);
    const startY = useRef(null);
    const [ launch, setLaunch ] = useState(true);
    const zone = useRef(null);

    useEffect(() => {
        if (saveProgress) {
            words.forEach(_word => {
                update(_word).then((event) => {
                    console.log(`${_word.id} edited`);
                });
            });
            setSaveProgress(false);
        }
    },[saveProgress]);

    useEffect(() => {
        if (learnAgain) {
            setWords(prevWords => (prevWords.map(_word => {
                update({..._word, progress: 12});
                return {..._word, progress: 12};
            })));
            setAllLearnt(false);
            setLearnAgain(false);
        }
    }, [learnAgain]);

    useEffect(() => {
        async function delayedSetWord(new_word) {
            setFlipped(false);
            if (args.directMode) {
                setWord(prevWord => ({...new_word, native: prevWord.native, nativeTranscription: prevWord.nativeTranscription}));
                setTimeout(() => {
                    return setWord(new_word);
                }, 600);
            } else {
                setWord(prevWord => ({...new_word, foreign: prevWord.foreign, foreignTranscription: prevWord.foreignTranscription}));
                setTimeout(() => {
                    return setWord(new_word);
                }, 600);
            }
        }

        if (change) {
            const wordId = word ? word.foreign : null;
            const filteredWords = words.filter(obj => obj.foreign !== wordId);
            const randomCumProgree = filteredWords.reduce((sum, obj) => sum + obj.progress, 0) * Math.random();
            if (randomCumProgree === 0 && word) {
                if (isFlipped) setFlipped(false);
                if (word.progress < 1) {
                    setChange(false);
                    setAllLearnt(true);
                    return;
                }
                else {
                    setChange(false);
                    return;
                }
            }
            
            let cumProgress = 0;
            for (const word3 of filteredWords) {
                if (word3.progress === 0) continue;
                cumProgress += word3.progress;
                if (randomCumProgree <= cumProgress) {
                    if (isFlipped) {
                        delayedSetWord(word3);
                        break;
                    }
                    else {
                        setWord(word3);
                        break;
                    }
                }
            }
            if (launch) {
                setChange(false);
                setLaunch(false);
            }
            else {
                setTimeout(() => {
                    return setChange(false);
                }, 500);
            }
        }
    },[change]);

    const handleKeyDown = async (event) => {
        if (event.key === 'ArrowRight') {
            event.preventDefault();
            setChange(true);
        } else if (event.key === 'ArrowDown') {
            event.preventDefault();
            setFlipped(true);
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            setFlipped(false);
        } else if (event.key === ' ' || event.code === 'Space') {
            event.preventDefault();
            setGlowing(true);
            setWord(prevWord => ({...prevWord, progress: Math.max(prevWord.progress - 1, 0)}));
            setPropProgress(true);
            setTimeout(() => {
                setGlowing(false);
            }, 200);
        } else if (event.key === 'ArrowLeft') {
            event.preventDefault();
            setSaveProgress(true);
            navigate('/dictionaries');
        }
    };

    useEffect(() => {
        if (propProgress) {
            const newData = words.map(_word =>
                _word.id === word.id ? { ..._word, progress: Math.max(_word.progress - 1, 0) } : _word
            );
            setWords(newData);
            setPropProgress(false);
        }
    },[propProgress]);

    useEffect(() => {
        setChange(true);
    }, [words]);

    const handleTouchStart = (event) => {
        if (event.touches.length >= 2) { 
            setGlowing(true);
            setWord(prevWord => ({...prevWord, progress: Math.max(prevWord.progress - 1, 0)}));
            setPropProgress(true);
            setTimeout(() => {
                setGlowing(false);
            }, 200);
        } else {
            startX.current = event.touches[0].clientX;
            startY.current = event.touches[0].clientY;
        }
    };

    const handleTouchEnd = () => {
        startX.current = null;
        startY.current = null;
    };

    useEffect(() => {
        if (args.selectedDict === null) navigate('/dictionaries');

        function handleTouchMove(event) {
            event.preventDefault();
            if (!startX.current || !startY.current) {
                return;
            } else if (event.touches.length >= 2) { 
                startX.current = null;
                startY.current = null;
                setGlowing(true);
                setWord(prevWord => ({...prevWord, progress: Math.max(prevWord.progress - 1, 0)}));
                setPropProgress(true);
                setTimeout(() => {
                    setGlowing(false);
                }, 200);
            } else { 
                const currentX = event.touches[0].clientX;
                const currentY = event.touches[0].clientY;
        
                const deltaX = currentX - startX.current;
                const deltaY = currentY - startY.current;
        
                if (deltaX > 150) {
                    setSaveProgress(true);
                    startX.current = null;
                    startY.current = null;
                    navigate('/dictionaries');
                } else if (deltaX < -120) {
                    setChange(true);
                    startX.current = null;
                    startY.current = null;
                }
    
                if (deltaY < -120) { // up
                    setFlipped(false);
                    startX.current = null;
                    startY.current = null;
                } else if (deltaY > 120) { // down
                    setFlipped(true);
                    startX.current = null;
                    startY.current = null;
                }
            }
        }

        getAll().then((theWords) => {
            const theWordsInSelectedDict = theWords.filter(obj => obj.dictionary === args.selectedDict);
            setWords(theWordsInSelectedDict);
            setAllLearnt(theWordsInSelectedDict.reduce((sum, obj) => sum + obj.progress, 0) === 0);
        });
            
        if (zone.current) {
            zone.current.addEventListener('touchmove', handleTouchMove, { capture: true, passive: false });
            zone.current.addEventListener('touchstart', handleTouchStart, { capture: true, passive: false });
            zone.current.addEventListener('touchend', handleTouchEnd, { capture: true, passive: false });
        }
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            if (zone.current) {
                zone.current.removeEventListener('touchmove', handleTouchMove);
                zone.current.removeEventListener('touchstart', handleTouchStart);
                zone.current.removeEventListener('touchend', handleTouchEnd);
            }
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
  
    if (!allLearnt) return (<div className="learning">
                            <div className="dictTitle">{args.selectedDict}</div>
                            <div ref={zone} className={`card ${isFlipped ? 'flipped' : ''}`}>
                                <div className="flip-card-inner">
                                    <div className={`flip-card-front ${isGlowing ? 'glow' : ''} ${args.directMode ? 'flip-card-foreign' : 'flip-card-native'}`}>
                                        {args.directMode ?
                                            <div>
                                                {word ? word.foreign : ''}
                                                {word && word.foreignTranscription ? <><br/><span className="transcription">[{word.foreignTranscription}]</span></> : <></>}
                                            </div> 
                                        : 
                                            <div>
                                                {word ? word.native : ''}
                                                {word && word.nativeTranscription ? <><br/><span className="transcription">[{word.nativeTranscription}]</span></> : <></>}
                                            </div>
                                        }
                                        
                                    </div>
                                    <div className={`flip-card-back ${isGlowing ? 'glow' : ''} ${args.directMode ? 'flip-card-native' : 'flip-card-foreign'}`}>
                                        {args.directMode ? 
                                            <div>
                                                {word ? word.native : ''}
                                                {word && word.nativeTranscription ? <><br/><span className="transcription">[{word.nativeTranscription}]</span></> : <></>}
                                            </div>
                                        : 
                                            <div>
                                                {word ? word.foreign : ''}
                                                {word && word.foreignTranscription ? <><br/><span className="transcription">[{word.foreignTranscription}]</span></> : <></>}
                                            </div> 
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="bottom-reco">press Space or touch with two fingers to mark you recognized it</div>
                        </div>);

   else return (
        <div className="learning">
            <div className="all-learnt">
                <p>You learnt all the words in this dictionary</p>
                <div className="all-learnt-container">
                    <Link to="/">
                        <button className="menuButton">
                            <em>B</em>ack to menu
                        </button>
                    </Link>
                    <button className="menuButton" onClick={()=> {
                            setLearnAgain(true);
                        }}>
                        <em>L</em>earn this dictionary again
                    </button>
                </div>  
            </div>
        </div>
    );
}